<script lang="ts">
    import {FormEntry} from "@shared/Form/interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"

    export let entry: FormEntry
</script>

{#if !(entry.disabled && entry.value === "")}
    <div {...entry.rowAttr} class="flex flex-col">
        {#if entry.label !== null}
            <label class="font-bold text-neutral-800 relative" {...entry.labelAttr}>
                {@html entry.label}
                {#if !entry.disabled && entry.required}
                    <span class="absolute ml-1 text-red-800">*</span>
                {/if}
            </label>
        {/if}
        {#if entry.disabled}
            <div class="py-2 mt-1">{entry.value}</div>
        {/if}
        <textarea
                rows="4"
                {...entry.attr}
                autocomplete="off"
                class="mt-1 bg-white rounded-md shadow-sm px-4 py-2 focus-visible:outline-0 focus-visible:shadow-md {entry.disabled ? 'hidden' : ''}"
                disabled={entry.disabled ? 'disabled' : null}
                id={entry.id}
                name={entry.fullName}
                on:input={e => entry.value = e.target.value}
                on:input
                on:blur
                on:focus
                required={entry.required ? 'required' : null}
                type={entry.type}
                value={entry.value}
        ></textarea>

        <FormTypeErrors errors={entry.errors}/>

        {#if entry.help}
            <small class="mt-3">{@html entry.help}</small>
        {/if}
    </div>
{/if}
